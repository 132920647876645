.Dashboard{
    border-top: solid 1px rgba(184, 184, 184, .3);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.DashFilter{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.DeviceFilter{
    cursor: pointer;
    margin: 5px;
    width: 60px;
    height: 60px;
}

.RoomsFilter{
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.RoomsFilter__room_name{
    cursor: pointer;
    opacity: .7;
    border: solid 1px;
    border-radius: var(--standart-border-radius);
    height: 30px;
    padding-top: auto;
    padding-bottom: auto;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    margin: 10px 5px;
    font-size: small;
}