.ViewDevice{
    border: 1px solid;
    border-radius: var(--standart-border-radius);
    margin: 10px;
	transition: all 1000ms ease;
    cursor: pointer;
}

.ViewDeviceCollapsed{
    width: 150px;
    height: 150px;
}

.ViewDeviceExpanded{
    width: unset;
    height: 150px;
}

.ViewDevice .Name{
    max-width: calc(100% - 10px);
    margin: 5px;
    height: 30px;
    font-size: small;
    white-space: normal;
    display: flex;
    flex-wrap: nowrap;
}

.ViewDevice .Name p{
    margin-left: 20px;
    flex-grow: 1;
}

.ViewDevice .Name img{
    margin: 0px;
    width: 20px;
    height: 20px;
}

.ViewDevice__Sensors{
    width: 100%;
    display: flex;
}

.ViewDevice__Sensors__ShortBlock{
    width: 130px;
    margin: 0px 10px;
    max-width: 130px;
}

.ViewDevice__Sensors__ShortBlock__Button{
    border: 1px solid rgba(0,0,0,0);
    border-radius: 15px;
}

.ViewDevice__Sensors__MainBlock__ShortImage{
    width: 70px;
    max-width: 70px;
    margin: 0px 30px;
}

.ViewDevice__Sensors__MainBlock__ShortImage > svg{
    max-height: 100px;
}



.ViewDevice__Sensors__MainBlock{
    width: 130px;
    margin: 0px 10px;
}

.ViewDevice__Sensors__MainBlock__MainImage{
    width: 100%;
    max-width: 130px;
}

.ViewDevice__Sensors__MainBlock__MainImage > svg{
    max-height: 100px;
}

.ViewDeviceOptions{
    width: 400px;
    min-width: 400px;
    margin: 0px auto;
}

