@charset "UTF-8";

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

html{
  min-height: 100%;
}

#body{
  margin: 0px;
  min-width: 600px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2C98D2 transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #2C98D2;
  border-radius: 10px;
  border: 0px solid transparent;
}

#root{
  width: 100%;
  height: 100%;
  --standart-border-radius: 10px;
  --menu-width-collapsed: 70px;
  --menu-width-opened: 200px;
  --settings-width: 800px;
  --app-header: 80px;
  --app-footer: 0px;
}

.App {
  text-align: center;
  vertical-align: middle;
  font-family: 'Roboto';
  font-weight: 200;
  font-style: normal;
  font-size: medium;

  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: hidden;
}

.AppDark {
  background-color: #282828;
  color: #B8B8B8;
}

.AppLight {
  background-color: #FFF;
  color: #6B6965;
}

.AppLogged{
  width: 100%;
  height: 100%;
}

.app__main__content{
  padding-left: var(--menu-width-collapsed);
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.AppHeader{
  width: 100%;
  height: var(--app-header);
}

.AppContent{
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - var(--app-header) - var(--app-footer));
  overflow-y: auto;
  overflow-x: hidden;
}

.AppFooter{
  width: 100%;
  height: var(--app-footer);
}




/******************** input css **********************/
.App input{
  padding: 0px 10px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #FFF;
  border-radius: 0px;
  background: transparent;
}

.App input:focus{
  border-color: #FD5C02;
  outline: none;
}

.AppDark input{
  color: #B8B8B8;
}

.AppLight input{
  color: #6B6965;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 500s ease-in-out 0s;
}

.AppDark input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #B8B8B8;
  color: #B8B8B8;
}

.AppLight input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #6B6965;
  color: #6B6965;
}

/******************************************************/

/******************** button css **********************/
.App button{
  min-height: 45px;
  height: 45px;
  padding: 0px 10px;
  margin: 0px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;

  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.App button:focus{
  border-color: #FD5C02;
  outline: none;
}

.App button:disabled{
  opacity: .5;
  cursor: not-allowed;
}

.AppDark button{
  background-color: #6B6965;
  border-color: #6B6965;
  color: #B8B8B8;
}

.AppLight button{
  background-color: #2C98D2;
  border-color: #B8B8B8;
  color: white;
}


.AppDark button:hover{
  background-color: #494845;
}

.AppLight button:hover{
  background-color: #2275a1;
}
/*********************************************************/


/******************** animation css **********************/


@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }


/*********************************************************/


/******************** checkbox css **********************/

/* для элемента input c type="checkbox" */
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: #b3d7ff;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox:focus+label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked)+label::before {
  border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled+label::before {
  background-color: #e9ecef;
}

.checkbox {
  margin-bottom: 1em;
}

/*********************************************************/

.roomImageBigShadow {
  -moz-box-shadow:    inset 5em 0em #000000;
  -webkit-box-shadow: inset 5em 0em #000000;
  box-shadow:         inset 0px 60px 60px #000000DD, inset 0px -50px 30px #000000DD;
}

.ant-popover-inner{
  border: 1px #2C98D2 solid !important;
  border-radius: 25px !important;
  background-color: #282828 !important;
}

.ant-popover-title{
  width: 100% !important;
  border-bottom: none !important;
  margin: 5px auto !important;
}

.ant-popover-arrow{
  /* border-right-color: #2C98D2 !important;
  border-bottom-color: #2C98D2 !important;
  border-top-color: #2C98D2 !important;
  border-left-color: #2C98D2 !important; */

  border-right-color: #2c98d200 !important;
  border-bottom-color: #2c98d200 !important;
  border-top-color: #2c98d200 !important;
  border-left-color: #2c98d200 !important;
}

/******************** button in popup css **********************/

.ant-popover-inner > div > div > button{
  padding: 0px 10px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
}

/*********************************************************/

/******************** select **********************/

.App select {
  height: 45px;
  padding: 0px 10px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
  background-color: transparent;
}

.App select:focus{
  border-color: #FD5C02;
  outline: none;
}

.AppDark select{
  background-color: #282828;
  border-color: #B8B8B8;
  color: #B8B8B8;
}

.AppLight select{
  background-color: #FFF;
  border-color: #6B6965;
  color: #6B6965;
}

/*********************************************************/

radios>input[type="radio"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}

/******************** ant-picker css **********************/
.App .ant-picker{
  border: 1px solid;
  border-radius: 5px;
}

.AppDark .ant-picker{
  background-color: #6B6965 !important;
  border-color: #6B6965 !important;
  color: #B8B8B8 !important;
}

.AppLight .ant-picker{
  background-color: #2C98D2 !important;
  border-color: #B8B8B8 !important;
  color: #B8B8B8 !important;
}

.AppDark .ant-picker-disabled{
  background-color: #6B6965 !important;
  border-color: #6B6965 !important;
  color: #B8B8B8 !important;
}

.AppLight .ant-picker-disabled{
  background-color: #2C98D2 !important;
  border-color: #B8B8B8 !important;
  color: white !important;
}

.AppDark .ant-picker-input > input{
  background-color: #6B6965 !important;
  border-color: #6B6965 !important;
  color: #B8B8B8 !important;
}

.AppLight .ant-picker-input > input{
  background-color: #2C98D2 !important;
  border-color: #B8B8B8 !important;
  color: white !important;
}

.AppDark .ant-picker-input > .ant-picker-suffix{
  color: #B8B8B8;
}

.AppLight .ant-picker-input > .ant-picker-suffix{
  color: white;
}

/*********************************************************/

/******************** app status *************************/

.App__WsOk{
  position: fixed;
  left: 15px;
  top: 15px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

/*********************************************************/

.imageInvertColors{
  -webkit-filter: invert(1);
  filter: invert(1);
}

.AddButton > svg{
    width: 80px;
    height: 80px;
    position: relative;
    top: calc(50% - 40px);
}