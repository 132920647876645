.SettingsAccount{
    width: var(--settings-width);
    min-width: var(--settings-width);
    margin: 0px auto;
}

.SettingsAccount > button {
    height: 45px;
    width: 300px;
    margin: 20px;
}

.SettingsAccount img {
    width: 280px;
    height: 280px;
    border: 2px solid rgb(39, 39, 39);
    border-radius: 140px;
    z-index: -1;
}

.SettingsAccount__File__Label{
    width: 300px;
    height: 45px;
    margin: 10px auto;
    border-radius: 10px;
    border: solid 1px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.SettingsAccount__File__Label > label{
    margin: auto 10px;
    text-align: center;
    cursor: pointer;
}

.SettingsAccount__Text{
    display: flex;
    margin: 5px 0px;
}

.SettingsAccount__Text > p{
    margin: 5px;
    text-align: right;
    width: -webkit-fill-available;
}

.SettingsAccount__Text > :first-child{
    text-align: left;
    min-width: 150px;
}