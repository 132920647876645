.svg-loading-back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.svg-loader {
    height: 100%;
    display: flex;
    position: relative;
    align-content: space-around;
    justify-content: center;

    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.loader-svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    fill: none;
    stroke-width: 5px;
    stroke-linecap: round;
    stroke: #FD5C02;
}

.loader-svg.bg {
    stroke-width: 8px;
    stroke: #2C98D2;
}

.animate {
    stroke-dasharray: 242.6;
    animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
    0% {
        stroke-dasharray: 40 242.6;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 141.3;
        stroke-dashoffset: 141.3;
    }
    100% {
        stroke-dasharray: 40 242.6;
        stroke-dashoffset: 282.6;
    }
}