.modal__history__container {
    z-index: 1150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal__history__container_dialog{
    z-index: 1151;
    max-height: calc(100% - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    position:fixed;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    
    /* -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px); */

    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid;
    border-radius: var(--standart-border-radius);
    transition: all 1000ms ease;
}

.modal__history__container_dialog > p{
    margin: 10px;
}

.modal__history__container_dialog > select{
    width: calc(100% - 20px);
    margin: 10px;
}

.modal__history__container_time{
    margin: 10px;
    width: 500px;
    display: flex;
}

.modal__history__container_time > p{
    margin: auto;
}

.modal__history__container_dialog__buttons{
    display: flex;
}

.modal__history__container_dialog__buttons > button{
    margin: 10px;
}

.custom-tooltip{
    border-radius: var(--standart-border-radius);
}
.custom-tooltip > p{
    margin: 10px;
}

.AppDark .custom-tooltip{
    background-color: rgba(0, 0, 0, 0.5);
    color: #9b9b9b;
}
  
.AppLight .custom-tooltip{
    background-color: rgba(0, 0, 0, 0.75);
    color: #B8B8B8;
}