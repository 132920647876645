.ViewRoom {
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: var(--standart-border-radius);
    margin: 10px 10px 0px 0px;
    transition: all 1000ms ease;
    width: 500px;
    height: 345px;
    position: relative;
    
    -moz-box-shadow:    inset 5em 0em #000000;
    -webkit-box-shadow: inset 5em 0em #000000;
    box-shadow:         inset 0px 60px 60px #000000DD, inset 0px -50px 30px #000000DD;
}

.ViewRoom img {
    position: absolute;
    max-width:100%;
    max-height:100%;
    width: 500px;
    height: 344px;
    right: 0;
    border: 0px;
    border-radius: var(--standart-border-radius);
    object-fit: cover;
    z-index: -1;
}

.ViewRoom__name {
    color: white;
    opacity: .75;
    font-size: x-large;
    margin: 5px;
}

.ViewRoom__Favourite{
    position: absolute;
    top: 15px;
    right: 15px;
}

.ViewRoom__Favourite > svg{
    height: 30px;
    width: 30px;
}

.ViewRoom__devices{
    width: 100%;
    height: 60%;

    position: absolute;
    bottom: 0;
    border: 0px;
    border-radius: 35px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
}

.ViewRoom__device__mainImage{
    width: 40px;
    height: 40px;
    margin: 5px 10px;
}