.SettingsButtonsDevice{
    border: 1px solid;
    border-radius: var(--standart-border-radius);
    margin: 10px;
	transition: all 1000ms ease;
    width: 150px;
    height: 150px;
    cursor: pointer;
}

.SettingsButtonsDevice__Name{
    margin: 5px;
    font-size: small;
    white-space: normal;
}

.SettingsButtonsDevice__Image{
    width: calc(100% - 30px);
    margin: 15px 15px 0px 15px;
}

.SettingsButtonsDevice__Image > svg{
    max-height: 100px;
}