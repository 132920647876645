.ViewEditButton{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
}

.ViewEditButton__Image{
    margin: 10px;
    line-height: 12px;
}

.ViewEditButton__Image > svg{
    max-height: 120px;
    max-width: 120px;

    margin: 10px;
}

.ViewEditButton__Buttons > button{
    margin: 5px 10px;
}