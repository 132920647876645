.ViewButton{
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
    margin: 10px 0px 10px 10px;
    
    cursor: pointer;

    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: var(--standart-border-radius);
}

.ViewButton > svg{
    max-height: 55px;
    max-width: 55px;
    margin-top: 5px;
}

.ViewButton > p{
    margin: 0px;
    font-size: small;
    white-space: normal;
}