.ViewEditDevice{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
}

.ViewEditDevice__Image img{
    height: 200px;
    margin: 10px;
    border-radius: 15px;
}

.ViewEditDevice__Sensor{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    line-height: 50px;
}

.ViewEditDevice__Sensor__Image{
    margin: auto 5px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
}

.ViewEditDevice__Sensor__Name{
    margin: auto 10px;
}

.ViewEditDevice__Map{
    height: 200px;
    margin: 10px;
    border-radius: 15px;
}

.Tracker__Map__Pin__Image{
    margin-top: -40px;
    margin-left: -20px;
    height: 40px;
    width: 40px;
}

.ViewEditDevice__Buttons > button{
    margin: 5px 10px;
}