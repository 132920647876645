.modal__container__passwordchange {
    z-index: 1150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal__container__passwordchange__dialog{
    z-index: 1151;
    max-height: calc(100% - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    position:fixed;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid;
    border-radius: var(--standart-border-radius);
    transition: all 1000ms ease;
}

.modal__container__passwordchange__dialog > p{
    width: calc(100% - 20px);
    margin: 10px;
}

.modal__container__passwordchange__dialog > input{
    width: calc(100% - 20px);
    margin: 10px;
}

.modal__container__passwordchange__dialog > button{
    width: calc(100% - 20px);
    margin: 10px;
}