.ViewEditRoom{
    width: 100%;
    position: relative;
    overflow: hidden;

}

.ViewEditRoom > img{
    position: absolute;
    width: 100%;
    height: 100%;

    opacity: .5;
    
    mask-image: linear-gradient(to bottom, rgb(255, 255, 255) 0%, transparent 100%);
}

/* .ViewEditRoom img {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;

    width: 100%;
    right: 0;
    border: 0px;
    border-top-left-radius: var(--standart-border-radius);
    border-top-right-radius: var(--standart-border-radius);
    -moz-box-shadow:    inset 5em 0em #000000;
    -webkit-box-shadow: inset 5em 0em #000000;
    box-shadow:         inset 0px 60px 60px #000000DD, inset 0px -50px 30px #000000DD;
} */

.ViewEditRoom__Devices{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 300px;
    overflow-y: auto;
}

.ViewEditRoom__Buttons > button{
    margin: 5px 10px;
}