.ViewScenario{
    border: 1px solid;
    border-radius: var(--standart-border-radius);
    margin: 10px;
	transition: all 1000ms ease;
    width: 150px;
    height: 150px;
    
    cursor: pointer;
}

.ViewScenario__Name{
    max-width: calc(100% - 10px);
    margin: 5px;
    height: 30px;
    font-size: small;
    white-space: normal;
    display: flex;
    flex-wrap: nowrap;
}

.ViewScenario__Name p{
    margin-left: 20px;
    flex-grow: 1;
}

.ViewScenario__Name img{
    margin: 0px;
    width: 20px;
    height: 20px;
}

.ViewScenario__icons{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    
    justify-content: center;
    margin: 20px 0px;
}

.ViewScenario__icon{
    width: 50px;
    height: 50px;
    margin: 10px;
}
.ViewScenario__icon > svg{
    width: 50px;
    height: 50px;
}