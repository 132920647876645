.ViewBaseModal {
    z-index: 150;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .ViewBaseModal__main {
    z-index: 101;
    max-height: calc(100% - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    position:fixed;
    width: auto;
    min-width: 700px;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

    padding: 20px 40px;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid;
    border-radius: var(--standart-border-radius);
    transition: all 1000ms ease;
  }

  .ViewBaseModal__main button {
      min-width: 200px;
      width: fit-content;
  }

  .ViewBaseModal__close{
    position: absolute;
    top: 5px;
    left: calc(100% - 35px);
    height: 30px !important;
    width: 30px !important;
    border-radius: 15px !important;
    opacity: .5;
  }

  .ViewBaseModal__close:hover{
    opacity: 1;
  }