.ViewIrButtons{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
    position: relative;
}

.ViewIrButtons__Sensors{
    width: 100%;
    max-height: 500px;
    display: flex;
    justify-content: center;
    
    flex-wrap: wrap;

    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 10px;
}

.ViewIrButtons__Sensors__Group{
    border-radius: var(--standart-border-radius);
    border: 2px solid;
    width: calc(100% - 10px);
    height: 50px;
    line-height: 50px;
    margin: 5px;
    cursor: pointer;
}

.ViewIrButtons__Sensors__Sensor{
    border: 1px solid;
    border-radius: var(--standart-border-radius);
    margin: 5px;
	transition: all 1000ms ease;
    cursor: pointer;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ViewIrButtons__Sensors__Sensor > p{
    font-size: small;
    margin: 25px 5px;
    word-break: keep-all;
    text-overflow: ellipsis;
}

.ViewIrButtons__Sensors__Sensor > img{
    position: absolute;
    top: 10px;
    right: 10px;
}