.modal__container__subscription {
    z-index: 1150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal__container__subscription__dialog{
    z-index: 1151;
    max-height: calc(100% - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    position:fixed;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid;
    border-radius: var(--standart-border-radius);
    transition: all 1000ms ease;
}

.modal__container__subscription__dialog > p{
    width: calc(100% - 20px);
    margin: 10px;
}

.modal__container__subscription__dialog__units{
    width: calc(100% - 20px);
    max-height: 500px;
    overflow-y: auto;
    margin: 10px;
}

.modal__container__subscription__dialog__unit{
    display: flex;
    flex-direction: row;
    font-size: small;
    cursor: pointer;
    margin: 5px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}

.modal__container__subscription__dialog__unit > .left{
    flex-grow: 1;
}

.modal__container__subscription__dialog__unit > .left > .cost{
    text-align: start;
}