.ViewCameraPreview{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.ViewCameraPreview__CamerasSelection{
    width: 100%;
    height: 90px;
}

.ViewCameraPreview__CamerasSelection > p{
    height: 45px;
    margin: auto 5px;
}

.ViewCameraPreview__CamerasSelection > .ant-select{
    height: 45px;
    margin: auto 5px;
}

.ViewCameraPreview__windows{
    z-index: -1;
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
}