.ViewLogin{
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

:root{
    --loginCredWidth: 500px;
}

.ViewLogin__logo{
    position: fixed;
    top: 20px;
    left: 20px;
    width: calc(var(--loginCredWidth) * .8 );
}

.ViewLogin__powered{
    position: fixed;
    top: calc(100% - 35px);
    left: 0px;
    width: 100%;
    line-height: 35px;
    text-align: right;
    padding-right: 20px;
    color: rgba(40, 140, 191, 1);
    text-shadow: 1px 1px 1px rgba(0,0,0, .4);
}

.ViewLogin__stores{
    position: fixed;
    top: calc(100% - 70px);
    left: 0px;
    width: 100%;
    text-align: right;
    padding-right: 20px;
}

.ViewLogin__stores > a > img{
    height: 40px;
    margin: 0px 3px;
    cursor: pointer;
}

.Credentials__UpButtons{
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    display: flex;
    flex-direction: row;
    padding: 20px 10px 10px 0px;
}

.Credentials__UpButtons__Button{
    color: #FFF !important;
    min-width: 80px !important;
    background: none !important;
    border-style: solid !important;
    width: fit-content !important;
    border-radius: 0px !important;
    margin-left: 5px !important;
    cursor: pointer !important;
}

.Credentials__UpButtons__Button :focus{
    border: none !important;
    outline: none;
  }

.Credentials{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 25px;

    height: calc(var(--loginCredWidth) - 70px);
    width: var(--loginCredWidth);
    background-color: rgba(16, 55, 77, 0.83);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.Credentials__form {
    margin: 30px;
}

.Credentials__form input{
    background-color: rgba(23, 75, 107, 1);
    border-radius: 5px;
    border: none;
}

.Credentials__form input :focus{
    border: 1px solid rgb(17, 52, 75);
}

.Credentials__form > input, select, textarea{
    color: #fff !important;
}

.Credentials__form__AuthForgotPasswordLink{
    color: #FFF;
    text-align: left;
    font-size: small;
    margin-left: 10px;
    cursor: pointer;
}

.Credentials__form__DownButtons{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.Credentials__form__Demo{
    width: calc(20%)!important;
    height: 44px!important;
    color: black !important;
    border-radius: 22px!important;
    background-color: #FFF!important;

}

.Credentials__form__AuthEnter{
    margin-left: auto !important;
    width: calc(70%)!important;
    height: 44px!important;
    color: black !important;
    border-radius: 22px!important;
    background-color: #FFF!important;
}

.Credentials__form__AuthRecoverAndReg{
    margin-top: 50px !important;
    width: calc(100%)!important;
    height: 44px!important;
    color: black !important;
    border-radius: 22px!important;
    background-color: #FFF!important;
}

.ViewLogin__langAndAbout{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: calc(100%);
}

.ViewLogin__about{
    width: min-content;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 10px;
    margin-left: auto;
}

.ViewLogin__about > img{
    width: var(--langButtonSize);
    height: var(--langButtonSize);
}

.ViewLogin__about > p{
    color: white;
    margin-left: 5px;
    font-size: small;
    white-space: nowrap;
}