.MainMenu{
    width: 100%;
    height: 60px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    top: calc(100% - 60px);
    position: fixed;
    border-top: solid 1px rgba(0,0,0,0.3);
}

.MenuItem{
    min-width: 80px;
    width: 100%;
    flex-grow: 10;
    font-size: small;
    
    text-align: center;
    vertical-align: middle;
}

.MenuItem img{
    width: 100%;
    height: 20px;
    max-width: 80px;
    margin: 10px 0px 0px 0px;
}

.menu_main_plus_img {
    position: relative;
    top: -15px;
    height: 55px !important;
}

.MenuItem div{
    line-height: 20px;
    height: 20px;
    margin: 5px 0px;
    width: 100%;

    text-align: center;
    vertical-align: middle;
}

.menu_item_selected{
    color: #2C98D2;
}