.modal__about__container {
    z-index: 1150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal__about__container_dialog{
    z-index: 1151;
    max-height: calc(100% - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    position:fixed;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid;
    border-radius: var(--standart-border-radius);
    transition: all 1000ms ease;
    padding: 5px;
    font-size: small;
}

.modal__about__container_dialog > img{
    width: 200px;
}

.modal__about__text > p{
    text-align: start;
    margin: 5px;
}

.modal__about__text__info {
    display: flex;
    flex-direction: row;
    margin: 5px;
}

.modal__about__text__info p{
    margin-right: 10px;
}

.modal__about__container_dialog__buttons{
    display: flex;
}

.modal__about__container_dialog__buttons > button{
    margin: 10px;
    min-width: 150px;
}