.Rooms{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 0px 10px;
}

.Rooms__buttons{
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: var(--standart-border-radius);
    margin: 10px 10px 0px;
	transition: all 1000ms ease;

    width: calc(100% - 20px);
    min-width: calc(100% - 20px);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    overflow-x: auto;
}