.viewRoomsSelector{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
    position: relative;
}

.viewRoomsSelector__Rooms{
    margin: auto;
    max-width: 500px;
}