.ViewEditScenario{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
}

.ViewEditScenario__Name{
    margin: auto 10px;
}

.ViewEditScenario__TimeFilter{
    border: 1px solid;
    border-radius: var(--standart-border-radius);
    padding: 10px;
}

.ViewEditScenario__TimeFilter__Days{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.ViewEditScenario__TimeFilter__Days button{
    min-width: 50px !important;
    height: 50px;
    width: 50px;
    border: 1px solid #696969;
    border-radius: var(--standart-border-radius);
    margin: 0px 10px;
}

.ViewEditScenario__TimeFilter__Days button:focus{
    border: 1px solid #696969;
}

.AppDark .ViewEditScenario__TimeFilter__Days button{
    color: #282828;
}
  
.AppLight .ViewEditScenario__TimeFilter__Days button{
    color: white;
}

.ViewEditScenario__TimeFilter__Radios{
    padding: 2px;
    border-bottom: 1px solid #64646433;
    line-height: 48px;
}

.ViewEditScenario__TimeFilter__Radios > label{
    line-height: 48px;
    vertical-align: middle;
    text-align: start;
}

.ViewEditScenario__IfThen_Section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.ViewEditScenario__IfThen_Section__If{
    border: 1px solid;
    border-radius: var(--standart-border-radius);
    margin: 10px 0px 10px 5px;
    width: 300px;
    flex-grow: 1;
    position: relative;
}

.ViewEditScenario__IfThen_Section__Then{
    border: 1px solid;
    border-radius: var(--standart-border-radius);
    margin: 10px 0px 10px 5px;
    width: 300px;
    flex-grow: 1;
    position: relative;
}

.ViewEditScenario__If > p{
    margin: 5px;
    line-height: 30px;
    cursor: pointer;
}

.ViewEditScenario__Then > p{
    margin: 5px;
    line-height: 30px;
    cursor: pointer;
}

.ViewEditScenario__If__MainImage{
    position: absolute;
    top: 5px;
    right: 15px;
    width: 30px;
    height: 30px;
    pointer-events: none;
}

.ViewEditScenario__Then__MainImage{
    position: absolute;
    top: 5px;
    right: 15px;
    width: 30px;
    height: 30px;
    pointer-events: none;
}

.ViewEditScenario__IfThen__Conditions{
    margin: 5px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}