.AppTitle {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    position: relative;

    border-bottom: 1px solid rgba(128, 128, 128, .5);
}

.AppTitle .CloudLeft {
    position: absolute;
    top: 0px;
    height: var(--app-header);
    left: 0px;
    width: 400px;
}

.AppTitle .CloudRight {
    position: absolute;
    top: 0px;
    height: var(--app-header);
    left: calc(100% - 400px);
    width: 400px;
}

.AppTitle__City {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 180px);
    width: 360px;
    height: 30px;
    max-height: 30px;
    text-align: center;
    margin: auto;
    white-space: nowrap;
    font-size: medium;
}

.AppTitle__Weather {
    width: 25%;
    height: calc(100% - 10px);
    position: absolute;
    left: 75%;
    top: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 5px 0px;
}

.AppTitle__AppStatusInfo{
    width: 25%;
    height: calc(100% - 10px);
    position: absolute;
    left: 0%;
    top: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 5px 0px;
    margin-left: 10px;
}

.AppTitle__AppStatusInfo, .AppTitle__Weather > span {
    font-size: small;
    text-align: left;
}

/* .AppTitle__Temp {
    position: absolute;
    top: 0px;
    left: calc(50% - 180px);
    height: 30px;
    max-height: 30px;
    text-align: center;
    margin: auto;
    white-space: nowrap;
    font-size: small;
}

.AppTitle__Hum {
    position: absolute;
    top: 0px;
    left: calc(50% + 90px);
    height: 30px;
    max-height: 30px;
    text-align: center;
    margin: auto;
    white-space: nowrap;
    font-size: small;
}

.AppTitle__Pressure {
    position: absolute;
    top: 30px;
    left: calc(50% - 65px);
    height: 30px;
    max-height: 30px;
    text-align: center;
    margin: auto;
    white-space: nowrap;
    font-size: small;
} */

.AppTitle__Ring {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(100% - 100px);
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.AppTitle__Ring__Count {
    position: absolute;
    top: 5px;
    left: calc(100% - 70px);
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px;
    font-size: x-small;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    cursor: pointer;
    line-height: 24px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: none;
    text-align: center;
    margin: auto;
}