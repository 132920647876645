.ViewEditTimer{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
}

.ViewEditTimer__WeekDays{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.ViewEditTimer__Days{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.ViewEditTimer__Days button{
    min-width: 50px !important;

    height: 50px;
    width: 50px;
    border: 1px solid #696969;
    border-radius: 25px;
    margin: 0px 10px;

}

.ViewEditTimer__Days button:focus{
    border: 1px solid #696969;
    
}

.AppDark .ViewEditTimer__Days button{
    color: #282828;
}
  
.AppLight .ViewEditTimer__Days button{
    color: white;
}

.ViewEditTimer__Radios{
    padding: 2px;
    border-bottom: 1px solid #64646433;
    line-height: 48px;
}

.ViewEditTimer__Radios > label{
    line-height: 48px;
    vertical-align: middle;
    text-align: start;
}

.ViewEditTimer__Buttons > button{
    margin: 5px 10px;
}