.SettingsPromaSat{
    width: var(--settings-width);
    min-width: var(--settings-width);
    margin: 0px auto;
}

.SettingsPromaSat__Units{
    border-radius: 15px;
    border: 1px solid;
    max-height: 500px;
    overflow-y: auto;
}

.SettingsPromaSat__Unit{
    cursor: pointer;
    min-height: 60px;
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.SettingsPromaSat__Unit__Name{
    text-align: start;
    margin: auto 10px;
    flex-grow: 1;
}

.SettingsPromaSat__Unit__Status{
    text-align: end;
    margin: auto 10px;
    opacity: 0.5;
    flex-grow: 1;
}