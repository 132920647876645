.ViewRoomNew{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
    position: relative;
    overflow: hidden;
}

.ViewRoomNew__Rooms{
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 35px;
    margin: 5px auto;
    transition: all 1000ms ease;
    width: 500px;
    height: 345px;
    
    -moz-box-shadow:    inset 5em 0em #000000;
    -webkit-box-shadow: inset 5em 0em #000000;
    box-shadow:         inset 0px 60px 60px #000000DD, inset 0px -50px 30px #000000DD;
}

.ViewRoomNew__Rooms img {
    width: 500px;
    height: 345px;
    right: 0;
    border: 0px;
    border-radius: 35px;
    object-fit: cover;
}

.ViewRoomNew__Devices{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 300px;
    overflow-y: auto;
}

.ViewRoomNew__Sensor{
    margin: 10px 5px;
}

.ViewRoomNew__Rooms__CustomImage__File__Label{
    position: absolute;
    top:70%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 200px;
    height: 45px;
    margin: 10px auto;
    border-radius: 10px;
    border: solid 1px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.ViewRoomNew__Rooms__CustomImage__File__Label > label{
    margin:auto 10px;
    text-align: center;
    cursor: pointer;
}
/* .ViewRoomNew__Devices{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 300px;
} */