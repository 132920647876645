.ViewCameras{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 800px;
}

.ViewCameras__Cameras{
    max-height: 500px;
    overflow-y: auto;
}

.ViewCameras__Camera{
    height: 45px;
    display: flex;
    margin: 20px;
}

.ViewCameras__Camera > p{
    text-align: start;
    margin: auto 15px;
    flex-grow: 1;
}

.ViewCameras__Camera > button{
    margin: 0px 5px;
}

.ViewCameras__Camera__MainImage{
    width: 100%;
    max-width: 50px;
}

.ViewCameras__Camera__MainImage > svg{
    max-height: 50px;
}

.ViewCameras__Camera > iframe{
    width: 500px;
}