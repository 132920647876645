.SettingsEquipment{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.SettingsEquipmentTitle{
    border-radius: var(--standart-border-radius);
    border: 2px solid;
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin: 5px;
    cursor: pointer;
}