.SettingsApp {
    width: var(--settings-width);
    min-width: var(--settings-width);
    margin: 0px auto;
}

.SettingsApp__Strings{
    border-bottom: solid 1px rgba(0,0,0,0.3);
}

.SettingsApp__Strings > p {
    margin: 10px;
}

.SettingsApp__Strings__Inputs {
    display: flex;
    flex-direction: row;
    margin: 10px;
    justify-content: center;
}

.SettingsApp__Strings__Inputs > label{
    margin: auto 10px;
    cursor: pointer;
}

.SettingsApp__Strings__Inputs > input{
    margin: auto 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}