.ViewDeviceSelector {
    z-index: 151;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .ViewDeviceSelector__main {
    z-index: 101;
    max-height: calc(100% - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    position:fixed;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid;
    border-radius: var(--standart-border-radius);
    transition: all 1000ms ease;
  }

  .ViewDeviceSelector__main button {
      min-width: 200px;
      width: fit-content;
  }

  .ViewDeviceSelector__main__Devices{
    margin: 10px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .ViewDeviceSelector__main__Devices__Device{
    display: flex;
    flex-wrap: nowrap;
    min-width: 400px;
    margin: 5px;
    cursor: pointer;
  }

  .ViewCondition{
    display: flex;
    flex-wrap: nowrap;
    margin: 5px;
    width: calc(100% - 10px);
  }

  .ViewCondition > p{
    margin: auto auto auto 10px;
    text-align: start;
  }

  .ViewCondition__Device__MainImage{
    width: 40px;
    height: 40px;
  }

  .ViewDeviceSelector__main__Devices__Device__MainImage{
    width: 40px;
    height: 40px;
  }

  .ViewCondition__Device__DeviceImage{
    right: 0px;
    width: 40px;
    height: 40px;
  }

  .ViewDeviceSelector__main__Devices__Device > p{
    height: 30px;
    width: calc(100% - 30px);
    text-align: start;
    margin: 5px 10px;
  }

.ViewDeviceSelector__main__Selected{
  width: 100%;
  min-height: 60px;
}

.ViewDeviceSelector__main__Selected_Sensor{
  width: calc(100% - 20px);
  margin: 5px 10px;
  display: flex;
  flex-wrap: nowrap;
}

.ViewDeviceSelector__main__Selected_Sensor > p{
  min-width: 40%;
  text-align: start;
  margin: auto 0px;
}

.ViewDeviceSelector__main__Selected_Operators{
  width: calc(100% - 20px);
  margin: 5px 10px;
  display: flex;
  flex-wrap: nowrap;
}

.ViewDeviceSelector__main__Selected_Operators > p{
  min-width: 40%;
  text-align: start;
  margin: auto 0px;
}

.ViewDeviceSelector__main__Selected_Operators :last-child{
  margin: 0px 0px 0px 10px;
}

.ViewDeviceSelector__main__Selected_Radius{
  width: calc(100% - 20px);
  margin: 5px 10px;
  display: flex;
  flex-wrap: nowrap;
}

.ViewDeviceSelector__main__Selected_Radius > input{
  height: 45px;
}

.ViewDeviceSelector__main__Selected_Radius > p{
  min-width: 40%;
  text-align: start;
  margin: auto 0px;
}

.ViewDeviceSelector__main__Selected_Operators > input{
  height: 45px;
}