.modal__irnewbutton__container {
    z-index: 1150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal__irnewbutton__container_dialog{
    z-index: 1151;
    max-height: calc(100% - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    position:fixed;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid;
    border-radius: var(--standart-border-radius);
    transition: all 1000ms ease;
}

.modal__irnewbutton__container_dialog > p{
    margin: 10px;
}

.modal__irnewbutton__container_dialog__img > img{
    /* width: calc(100% - 20px);
    margin: 10px;
    border-radius: var(--standart-border-radius); */

    height: 200px;
    margin: 10px;
    border-radius: var(--standart-border-radius);
}

.modal__irnewbutton__container_dialog > input{
    width: calc(100% - 20px);
    margin: 10px;
}

.modal__irnewbutton__container_dialog__buttons{
    display: flex;
}

.modal__irnewbutton__container_dialog__buttons > button{
    margin: 10px;
}