.ViewSensor{
    height: 100%;
    width: 130px;
    margin: 10px;
}

.ViewSensor__MainImage{
    width: 100%;
    max-width: 130px;
    height: 60%;
}

.ViewSensor__MainImage > svg{
    max-height: 50px;
}

.ViewSensor__Name{
    height: 40%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: smaller;
    border: 1px solid rgba(0,0,0,0);
    border-radius: 15px;
}