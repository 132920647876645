.SettingsLocationsDevice{
    border: 1px solid;
    border-radius: 15px;
    margin: 10px;
	transition: all 1000ms ease;
    width: 150px;
    height: 150px;
}

.SettingsLocationsDevice__Name{
    margin: 5px;
    font-size: small;
    white-space: normal;
}

.SettingsLocationsDevice__Image{
    width: calc(100% - 30px);
    margin: 15px 15px 0px 15px;
}

.SettingsLocationsDevice__Image > svg{
    max-height: 100px;
}