.SettingsFriends{
    width: var(--settings-width);
    min-width: var(--settings-width);
    margin: 0px auto;
}

.SettingsFriends__Friends{
    border-radius: var(--standart-border-radius);
    border: 1px solid;
    max-height: 500px;
    overflow-y: auto;
}

.SettingsFriends__Friend{
    cursor: pointer;
    min-height: 100px;
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.SettingsFriends__Friend__Info{
    display: flex;
    flex-direction: column;
}

.SettingsFriends__Friend__Name{
    text-align: start;
    margin: auto 10px;
}

.SettingsFriends__Friend__Count{
    text-align: start;
    margin: auto 10px;
    opacity: 0.5;
    font-size: small;
}

.SettingsFriends__Friend__Status{
    text-align: end;
    margin: auto 10px;
    opacity: 0.5;
    flex-grow: 1;
    font-size: small;
}

.SettingsFriends__Friend > img{
    margin: 15px 10px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
}

.SettingsFriends__Friend__Mock{
    margin: 15px 10px;
    width: 60px;
    height: 60px;
}