.ViewMenu{
    width: var(--menu-width-collapsed);
    height: 100%;
    position: fixed;
    border-right: 1px solid rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;

    -webkit-transition: width .2s ease-in-out;
    -moz-transition: width .2s ease-in-out;
    -o-transition: width .2s ease-in-out;
    transition: width .2s ease-in-out;
}

.ViewMenu > img{
    height: calc(var(--menu-width-collapsed) - 20px);
    width: calc(var(--menu-width-collapsed) - 20px);
    margin: 25px 10px;
}

.ViewMenu p{
    transition: opacity .15s ease-in-out;
}

.viewMenu__item{
    margin: 20px 15px;
    width: calc(100% - 20px);
    height: 50px;

    cursor: pointer;
    font-size: small;
    text-align: start;
    vertical-align: middle;
}

.viewMenu__item img{
    height: calc(var(--menu-width-collapsed) - 30px);
    width: calc(var(--menu-width-collapsed) - 30px);
}

.menu_item_selected{
    color: #2C98D2;
}

.viewMenu__item__content{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.viewMenu__item__content img{
    min-height: calc(var(--menu-width-collapsed) - 30px);
    min-width: calc(var(--menu-width-collapsed) - 30px);
}

.viewMenu__item__content p{
    margin: auto 10px;
    font-size: medium;
}

.ViewMenu__additional > p {
    text-align: start;
    padding: 3px;
    margin-left: 10px;
    font-size: small;
}