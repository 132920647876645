.ViewEditPlace{
    width: calc(100% - 40px);
    margin: 20px;
    min-width: 500px;
}

.ViewEditPlace__Name{
    margin: auto 10px;
}

.ViewEditPlace__Map{
    height: 200px;
    margin: 10px;
    border-radius: 15px;
}

.Tracker__Map__Pin__Image{
    margin-top: -40px;
    margin-left: -20px;
    height: 40px;
    width: 40px;
}

.ViewEditPlace__Buttons > button{
    margin: 5px 10px;
}